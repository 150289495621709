<template>
  <div class="report-custom-detail">
    <div class="breadcrumb" v-show="!isFullscreen">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>咨询服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/consult/report-custom' }"
          >报告定制</el-breadcrumb-item
        >
        <div style="font-size: 16px; color: #333">报告定制详情</div>
      </el-breadcrumb>
    </div>
    <div class="top-box" v-show="!isFullscreen">
      <img @dragstart.prevent class="left-img" :src="bookUrl" alt="" />
      <div class="right-box">
        <div class="right-name">{{ detailData.name }}</div>
        <div class="item-box">
          <div class="item-name">报告简介：</div>
          <div class="item-main">{{ detailData.introduction }}</div>
        </div>
        <div class="item-box">
          <div class="item-name">咨询电话：</div>
          <div class="item-main">{{ servicePhone }}</div>
        </div>
        <!-- <div class="item-box">
          <div class="item-name">定制须知：</div>
          <div class="item-main">{{ detailData.notice }}</div>
        </div> -->
        <div class="item-box">
          <div class="item-name">定制说明：</div>
          <div class="item-main">{{ detailData.statement }}</div>
        </div>
        <div class="buy-box">
          <div class="btn-box">
            <div class="custom-btn" @click="doCustom">立即定制</div>
          </div>
        </div>
      </div>
    </div>
    <div class="file-box">
      <div class="file-name-box">
        <div class="file-img"></div>
        <div class="file-name">参考报告</div>
        <div class="download-box" @click="click">
          <span
            :class="
              isFullscreen ? 'iconfont icon-quxiaoquanping' : 'iconfont icon-quanping'
            "
          ></span>
          <span>{{ isFullscreen ? "取消全屏" : "全屏预览" }}</span>
        </div>
      </div>
      <div
        :class="isFullscreen ? 'full-file-content' : 'file-content'"
        @scroll="scrollEvent"
      >
        <div class="operation" v-show="isFullscreen">
          <img
            @dragstart.prevent
            src="@/assets/img/magnify.png"
            alt=""
            @click="toMagnify"
            v-show="scaleNum != 1"
          />
          <img
            @dragstart.prevent
            src="@/assets/img/magnify-stop.png"
            alt=""
            v-show="scaleNum == 1"
            style="cursor: not-allowed"
          />
          <div class="line"></div>
          <img
            @dragstart.prevent
            src="@/assets/img/shrink.png"
            alt=""
            @click="toShrink"
            v-show="scaleNum != -3"
          />
          <img
            @dragstart.prevent
            src="@/assets/img/shrink-stop.png"
            alt=""
            v-show="scaleNum == -3"
            style="cursor: not-allowed"
          />
          <div class="line"></div>
          <img
            @dragstart.prevent
            src="@/assets/img/refresh.png"
            alt=""
            @click="toRefresh"
          />
        </div>
        <!-- 报告内容...... -->
        <pdf v-for="item in pageArray" :key="item" :src="src" :page="item"></pdf>
      </div>
      <!-- <div class="nextShow-box" v-show="nextShow">
        <div class="masking"></div>
        <div class="text-box" @click="next()">
          <span class="member">继续阅读</span>
        </div>
      </div> -->
      <div class="masking-box">
        <!-- <div class="masking"></div> -->
        <div class="buy-box">
          <div class="btn-box">
            <div class="custom-btn" @click="doCustom">立即定制</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗dialog -->
    <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        立即定制
      </div>
      <el-form
        :model="dataPO"
        :rules="rules"
        ref="dataPO"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="您的姓名" prop="contactName" class="title-item">
              <el-input
                v-model.trim="dataPO.contactName"
                maxlength="200"
                placeholder="请输入您的姓名"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="您的联系方式" prop="contactNumber">
              <el-input
                v-model.trim="dataPO.contactNumber"
                placeholder="请输入联系座机号或手机号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="您的单位" prop="companyName" class="title-item">
              <el-input
                v-model.trim="dataPO.companyName"
                placeholder="请输入您的单位"
                maxlength="200"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="您的职务" prop="contactPost">
              <el-input
                v-model.trim="dataPO.contactPost"
                maxlength="200"
                placeholder="请输入您的职务"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="您的行业" prop="sector" class="title-item">
              <el-input
                v-model.trim="dataPO.sector"
                maxlength="200"
                placeholder="请输入您的行业"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="您期望的交付时间" prop="expectedDeliveryDate">
              <el-date-picker
                type="date"
                placeholder="请选择时间"
                v-model="dataPO.expectedDeliveryDate"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                style="width: 100%"
              ></el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item
              label="报告类型"
              prop="researchReportTypeCode"
              class="title-item"
              ><el-select
                v-model="dataPO.researchReportTypeCode"
                placeholder="请选择研究报告类型"
              >
                <el-option
                  v-for="(item, index) in reportTypeList"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="需求描述" prop="detail" class="desc-item title-item">
              <el-input
                type="textarea"
                v-model.trim="dataPO.detail"
                :rows="6"
                resize="none"
                maxlength="1000"
                placeholder="限1000字以内"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item class="title-item btn-item">
              <el-button type="primary" @click="submitForm">提交需求</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import pdf from "vue-pdf";
import { mapGetters } from "vuex";
export default {
  components: {
    Modal,
    pdf,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const moblie =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      const tel = /^(\d{3,4}-)?\d{7,8}$/;
      if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
        callback(new Error("请输入格式正确的联系方式"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      reportTypeList: [],
      dataPO: {
        contactName: "",
        contactNumber: "",
        companyName: "",
        contactPost: "",
        sector: "",
        expectedDeliveryDate: "",
        researchReportTypeCode: "",
        detail: "",
        terminal: "60441001",
      },
      rules: {
        contactName: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        companyName: [{ required: true, message: "请输入您的单位", trigger: "blur" }],
        contactNumber: [
          {
            required: true,
            message: "请输入联系座机号或手机号",
            trigger: "blur",
          },
          {
            validator: validatePhone,
          },
        ],
        expectedDeliveryDate: [
          { required: true, message: "请选择您期望的交付时间", trigger: "blur" },
        ],
        researchReportTypeCode: [
          { required: true, message: "请选择研究报告类型", trigger: "change" },
        ],
        detail: [{ required: true, message: "请输入您的需求描述", trigger: "blur" }],
      },
      visible: false,
      isFullscreen: false,
      type: "", // 研究报告类型
      detailData: {
        name: "",
        introduction: "",
        type: "电子版刊物",
        tel: "",
        notice:
          "您将购买的商品为虚拟内容服务，购买后不支持退款、转让、退换，请仔细斟酌确认！",
        statement:
          "本报告为定制内容，请阅读下方目录后，点击“立即定制”或通过客服反馈您的需求。",
      },
      numPages: "", // 总页数
      times: 1,
      yushu: 1,
      pageArray: [],
      currentPage: 1,
      nextShow: false,
      src: "",
      bookUrl: "",
      scaleNum: 1, // 放大缩小基数
    };
  },
  computed:{
    ...mapGetters(['servicePhone'])
  },
  mounted() {
    let type = this.$route.query.type;
    if (type == 1) {
      this.detailData.name = "区域市场研究报告";
      this.detailData.introduction =
        "综合分析目标区域市场的经济、财政、投资、政策、产业等情况，结合项目招中标大数据，对市场体量、市场分布、市场集中度开放度、客户及竞争对手进行系统深入地分析，帮助用户企业制定针对性市场开发策略。";
      this.bookUrl = require("../../../assets/img/consult/reportCustom/book-one.jpg");
      this.src = "/static/pdf-file1.pdf";
    } else if (type == 2) {
      this.detailData.name = "细分市场研究报告";
      this.detailData.introduction =
        "综合分析建筑业细分市场的规模地位、政策导向、产业布局等，结合项目招中标情况，对细分市场的分支领域进行挖掘分析，帮助用户企业制定针对性新业务开发策略。";
      this.bookUrl = require("../../../assets/img/consult/reportCustom/book-two.jpg");
      this.src = "/static/pdf-file2.pdf";
    } else if (type == 3) {
      this.detailData.name = "客户研究报告";
      this.detailData.introduction =
        "对目标区域或领域客户的业务范围、经营状况、战略规划、业绩要求、评标规则、工程降造、历史供应商、关联关系等情况进行综合分析，结合客户需求及竞争情况，帮助用户企业制定针对性客户开发策略。";
      this.bookUrl = require("../../../assets/img/consult/reportCustom/book-three.png");
      this.src = "/static/pdf-file3.pdf";
    } else {
      this.detailData.name = "竞争研究报告";
      this.detailData.introduction =
        "综合分析目标市场的竞争环境、竞争态势，识别主要竞争对手并开展优劣势分析，结合目标市场特点及目标客户需求，帮助用户企业制定针对性市场竞争策略。";
      this.bookUrl = require("../../../assets/img/consult/reportCustom/book-four.png");
      this.src = "/static/pdf-file4.pdf";
    }
    this.type = type;
    this.reportCustomType();
    this.getPdfDetail();
  },
  methods: {
    scrollEvent(e) {
      if (
        Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=
        e.currentTarget.scrollHeight
      ) {
        //容差：20px
        console.log("滚动到底部");
        if (this.currentPage == this.times - 1) {
          this.nextShow = false;
        }
        if (this.currentPage == this.times) {
          // this.nextShow = false;
          return;
        }
        if ((this.currentPage + 1) * 5 <= this.numPages) {
          for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
            this.pageArray.push(i);
          }
        } else {
          for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
            this.pageArray.push(i);
          }
        }
        this.currentPage++;
      }
    },
    // 点击放大
    toMagnify() {
      let { scaleNum } = this;
      scaleNum += 1;
      this.scaleNum = scaleNum;
      document.querySelector(".full-file-content").style.padding = `10px ${
        300 - (scaleNum - 1) * 50
      }px`;
    },
    // 点击缩小
    toShrink() {
      let { scaleNum } = this;
      scaleNum -= 1;
      this.scaleNum = scaleNum;
      document.querySelector(".full-file-content").style.padding = `10px ${
        300 - (scaleNum - 1) * 50
      }px`;
    },
    // 点击刷新
    toRefresh() {
      this.scaleNum = 1;
      document.querySelector(".full-file-content").style.padding = "10px 300px";
    },
    // 获取报告定制类型
    reportCustomType() {
      this.$api.consult
        .reportCustomType({
          type: "6052",
        })
        .then((res) => {
          this.reportTypeList = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取pdf详情
    getPdfDetail() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false,
      });
      let src = pdf.createLoadingTask({
        url: this.src,
        CMapReaderFactory,
        // cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
        // cMapPacked: true
      });
      console.log(this.src);
      // 获取pdf页数
      src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
        loading.close();
        this.times = Number.parseInt(this.numPages / 5);
        this.yushu = this.numPages % 5;

        if (this.yushu > 0) {
          this.times++;
          if (this.times == 1) {
            this.nextShow = false;
            for (let i = 1; i <= this.yushu; i++) {
              this.pageArray.push(i);
            }
          } else {
            this.nextShow = true;
            for (let i = 1; i <= 5; i++) {
              this.pageArray.push(i);
            }
          }
        } else if (this.yushu == 0) {
          if (this.times == 1) {
            this.nextShow = false;
          } else {
            this.nextShow = true;
          }
          for (let i = 1; i <= 5; i++) {
            this.pageArray.push(i);
          }
        }
      });
    },
    // 超出继续阅读
    next() {
      console.log(this.currentPage, this.times);
      if (this.currentPage == this.times - 1) {
        this.nextShow = false;
      }
      if (this.currentPage == this.times) {
        // this.nextShow = false;
        return;
      }
      if ((this.currentPage + 1) * 5 <= this.numPages) {
        for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
          this.pageArray.push(i);
        }
      } else {
        for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
          this.pageArray.push(i);
        }
      }
      this.currentPage++;
    },
    // 网页全屏方法
    click() {
      // if (!screenfull.isEnabled) {
      //   this.$message({ message: "你的浏览器不支持全屏", type: "warning" });
      //   return false;
      // }
      // screenfull.toggle();
      if (this.isFullscreen) {
        this.toRefresh();
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "1200px";
        document.querySelector(".file-content").style.padding = "10px 200px";
      } else {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "100vw";
      }
    },
    // 推荐阅读书本点击事件
    toDetail() {},
    //  点击立即定制按钮
    doCustom() {
      this.visible = true;
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.dataPO.resetFields();
    },
    // 表单提交事件
    submitForm() {
      this.$refs["dataPO"].validate((valid) => {
        if (valid) {
          this.$api.consult
            .reportCustom(this.dataPO)
            .then(() => {
              this.$message.success("提交成功");
              this.visible = false;
              this.$refs.dataPO.resetFields();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
